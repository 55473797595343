<nav aria-label="breadcrumb">
	<ol class="breadcrumb">
		<li class="flex items-center">
			<a [routerLink]="['/']" class="text-2xl px-2 cursor-pointer">
				<mat-icon inline class="material-symbols-filled align-bottom">home</mat-icon>
			</a>
			<mat-icon inline class="px-2 align-bottom">arrow_forward_ios</mat-icon>
		</li>

		@for (item of breadcrumbs; track $index + item.url; let last = $last) {
			<li class="breadcrumb-c-item" [class.active]="item === breadcrumbs[breadcrumbs.length - 1]">
				@if (last) {
					<span class="text-lg px-2">{{ item.label | titlecase }}</span>
				} @else {
					<a [routerLink]="[item.url]" class="px-2">{{ item.label | titlecase }}</a>
					<mat-icon inline class="px-2 align-bottom">arrow_forward_ios</mat-icon>
				}
			</li>
		}
	</ol>
</nav>

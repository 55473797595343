import { Routes } from '@angular/router';

import { FirewallsComponent } from './firewalls/firewalls.component';

const baseTitle = '| Policies | eMyPeople';

export default <Routes>[
	{
		path: '',
		redirectTo: 'firewalls',
		pathMatch: 'prefix',
	},
	{
		path: 'firewalls',
		component: FirewallsComponent,
		title: `Firewalls ${baseTitle}`,
	},
];

import { A11yModule } from '@angular/cdk/a11y';
import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ApiService } from '@root/app/core/api/api.service';
import { DeviceService } from '@root/app/feature/device/device.service';
import { DeviceAndroid } from '@root/app/feature/devices/devices.types';
import { environment } from '@root/environments/environment';
import { NotificationService, NotificationVariation } from '@ui/notification/notification.service';

import { ButtonComponent } from '../../button/button.component';
import { LoaderComponent } from '../../loader/loader.component';
import { HUNDRED_YEARS_SECONDS, TIMER_OPTIONS } from './device-timer.constants';

@Component({
	selector: 'csd-app-device-timer',
	standalone: true,
	imports: [
		A11yModule,
		ButtonComponent,
		LoaderComponent,
		MatDialogModule,
		MatIconModule,
		MatSelectModule,
		ReactiveFormsModule,
	],
	templateUrl: './device-timer.component.html',
	styleUrl: './device-timer.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceTimerComponent implements OnInit {
	private apiService = inject(ApiService);
	private deviceService = inject(DeviceService);
	private dialogRef = inject(MatDialogRef<DeviceTimerComponent>);
	private fb = inject(FormBuilder);
	private notificationService = inject(NotificationService);

	currentTime = signal<string>('');
	device = this.deviceService.device;
	form = this.fb.group({
		timer: [undefined, [Validators.required, Validators.min(0)]],
	});
	loadingDevice = this.deviceService.isLoading;
	loadingSubmit = signal<boolean>(false);
	timerOptions = TIMER_OPTIONS;

	constructor(@Inject(MAT_DIALOG_DATA) public data?: DeviceAndroid) {}

	ngOnInit(): void {
		this.deviceService.getDevice(this.data?.id as string).subscribe();
	}

	getFutureDateTime(timer: number) {
		const timerValue = timer || HUNDRED_YEARS_SECONDS;
		const currentDate = new Date();
		const linuxTime = currentDate.getTime() / 1000;
		const dateWithTimer = (linuxTime + timerValue) * 1000;

		return formatDate(
			new Date(dateWithTimer),
			'yyyy-MM-ddThh:mm:ss',
			'en-US',
			environment.timezoneGMT,
		);
	}

	handleSubmit() {
		this.dialogRef.close({ success: true });
		this.notificationService.open({
			variation: NotificationVariation.SUCCESS,
			message: `The timer on <strong>${this.data?.name}</strong> has been set.`,
		});
	}

	onSubmit(event: SubmitEvent) {
		event.preventDefault();

		if (!this.form.valid || !this.device().id) {
			return;
		}

		const timerControlValue = this.form.controls.timer.value || 0;
		const datetime = this.getFutureDateTime(timerControlValue);

		this.submitData(datetime);
	}

	submitData(datetime: string) {
		this.loadingSubmit.set(true);

		const params = { endTime: datetime };
		const data = {
			id: this.device().id,
			created: this.device().created,
			updated: this.device().updated,
			active: this.device().active,
			policyAndroidSettings: this.device().policy.policyAndroidSettings,
			policyAppJoins: this.device().policy.policyAppJoins,
		};

		this.apiService
			.post(`/android/device/addtemporarypolicy/${this.data?.id}`, data, { params })
			.subscribe({
				next: () => this.handleSubmit(),
				error: (err) => {
					this.loadingSubmit.set(false);
					throw new Error(err.error?.title || err.message);
				},
				complete: () => {
					this.loadingSubmit.set(false);
				},
			});
	}
}

<div id="sessionModal" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
	<h2 mat-dialog-title>Your session is about to expire</h2>
	<mat-dialog-content>
		<p>For security reasons, your session will expire in the next 5 minutes.</p>
		<p>Do you want to stay signed in?</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end" class="space-x-4" cdkTrapFocus>
		<csd-app-button size="small" mat-dialog-close="close">Sign out now</csd-app-button>
		<csd-app-button
			size="small"
			mat-dialog-close="keep"
			[cdkFocusInitial]="true"
			variation="secondary"
			>Stay signed in</csd-app-button
		>
	</mat-dialog-actions>
</div>

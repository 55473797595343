const oneHourInSeconds = 3_600;

export const HUNDRED_YEARS_SECONDS = 3_155_760_000;

export const TIMER_OPTIONS = [
	{
		label: '1 Hour',
		value: oneHourInSeconds,
	},
	{
		label: '5 Hours',
		value: oneHourInSeconds * 5,
	},
	{
		label: '10 Hours',
		value: oneHourInSeconds * 10,
	},
	{
		label: '1 Day',
		value: oneHourInSeconds * 24,
	},
	{
		label: '2 Days',
		value: oneHourInSeconds * 24 * 2,
	},
	{
		label: '3 Days',
		value: oneHourInSeconds * 24 * 3,
	},
	{
		label: '1 Week',
		value: oneHourInSeconds * 24 * 7,
	},
	{
		label: 'Permanently',
		value: 0,
	},
];

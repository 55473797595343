import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';

export interface SectionMenuItem {
	icon: string;
	label: string;
	route: string;
}

@Component({
	selector: 'csd-app-section-menu',
	standalone: true,
	imports: [MatIconModule, RouterLink, RouterLinkActive],
	templateUrl: './section-menu.component.html',
	styleUrl: './section-menu.component.scss',
})
export class SectionMenuComponent {
	itemsList = input.required<SectionMenuItem[]>();
}

<div cdkDropList class="space-y-3" (cdkDropListDropped)="drop($event)">
	@for (rule of list(); track $index) {
		<div class="cdk-drag-box flex items-center bg-white rounded pl-3 pr-5 py-2" cdkDrag>
			<div class="cdk-drag-handle" cdkDragHandle>
				<mat-icon inline class="align-middle">expand_all</mat-icon>
			</div>
			<div class="cdk-drag-placeholder" *cdkDragPlaceholder></div>
			<div class="content-box w-full flex flex-col sm:flex-row items-center">
				<p class="grow m-0">{{ rule['name'] }}</p>
				<div class="flex">
					<csd-app-tag [classList]="getColorVariation(rule['owner'])">
						{{ toString(rule['owner']) | uppercase }}
					</csd-app-tag>
					<div class="item-actions pl-2">
						<button matRipple class="rounded px-2 py-1 text-xl leading-none">
							<mat-icon inline>edit</mat-icon>
						</button>
						<button matRipple class="text-layout-red rounded px-2 py-1 text-xl leading-none">
							<mat-icon inline>delete</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	}
</div>

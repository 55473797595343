<div class="device-apps">
	<div class="search-apps">
		<input
			matInput
			class="search-apps__input"
			placeholder="Search apps"
			[(ngModel)]="searchText"
			(ngModelChange)="filterApps($event)"
		/>
		@if (searchText) {
			<button (click)="clearSearch()" class="search-apps__button">
				<mat-icon inline class="align-middle">cancel</mat-icon>
			</button>
		}
	</div>
	<csd-app-loader [isLoading]="isLoading()">
		<div class="content-apps" [class.content-apps--empty]="!launcherApps().length">
			@for (item of launcherFilterApps(); track item.appId) {
				<csd-app-app-item
					class="app-item"
					[data]="item"
					[appsStatus]="appOptions()?.appStatuses || {}"
					(changedConfig)="updateStatus($event, item)"
				></csd-app-app-item>
			}

			@if (!launcherApps().length) {
				<csd-app-empty-state [onlyText]="true">
					<ng-content empty-state-message>
						<div class="flex flex-col items-center">
							<mat-icon>apk_document</mat-icon>
							No launcher apps in this device
						</div>
					</ng-content>
				</csd-app-empty-state>
			}
		</div>
		<csd-app-header-divider text="System Apps" />
		<div class="content-apps" [class.content-apps--empty]="!systemApps().length">
			@for (item of systemFilterApps(); track item.appId) {
				<csd-app-app-item
					class="app-item"
					[data]="item"
					[appsStatus]="appOptions()?.appStatuses || {}"
					(changedConfig)="updateStatus($event, item)"
				></csd-app-app-item>
			}

			@if (!systemApps().length) {
				<csd-app-empty-state [onlyText]="true">
					<ng-content empty-state-message>
						<div class="flex flex-col items-center">
							<mat-icon>apk_document</mat-icon>
							No system apps in this device
						</div>
					</ng-content>
				</csd-app-empty-state>
			}
		</div>
		@if (uninstalledApps().length) {
			<csd-app-header-divider text="Uninstalled Apps" />
			<div class="content-apps">
				@for (item of uninstalledFilterApps(); track item.appId) {
					<csd-app-app-item
						class="app-item"
						[data]="item"
						[appsStatus]="appOptions()?.appStatuses || {}"
						(changedConfig)="updateStatus($event, item)"
					></csd-app-app-item>
				}
			</div>
		}
	</csd-app-loader>
</div>

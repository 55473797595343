import { Component, OnInit, signal } from '@angular/core';
import { AuthService } from '@root/app/core/auth/auth.service';
import { LoaderComponent } from '@ui/loader/loader.component';

import { ActionsComponent } from './actions/actions.component';
import { ConnectService } from './connect.service';
import { STEPS } from './constants';
import { GuideComponent } from './guide/guide.component';
import { OptionsComponent, OptionSelection } from './options/options.component';
import { VerifyCodeComponent } from './verify-code/verify-code.component';

@Component({
	selector: 'csd-app-connect',
	standalone: true,
	templateUrl: './connect.component.html',
	styleUrl: './connect.component.scss',
	imports: [
		ActionsComponent,
		GuideComponent,
		LoaderComponent,
		OptionsComponent,
		VerifyCodeComponent,
	],
})
export class ConnectComponent implements OnInit {
	steps = STEPS;

	email = signal<string>('');
	isLoading = signal<boolean>(false);
	step = signal<string>(this.steps[0]);
	numbersList = signal<number[]>([]);
	optionSelected = signal<OptionSelection>({} as OptionSelection);

	constructor(
		private connectService: ConnectService,
		private authService: AuthService,
	) {}

	ngOnInit() {
		const userData = this.authService.userData();

		this.isLoading.set(true);
		this.email.set(userData?.email as string);
		this.connectService.checkEmail(userData?.email as string, this.loadNumbersList.bind(this));
	}

	loadNumbersList() {
		this.connectService.getNumbersList(this.email(), this.updateNumbersList.bind(this));
	}

	updateNumbersList(list: number[]) {
		this.isLoading.set(false);
		this.numbersList.set(list);
	}

	showVerify() {
		this.step.set(this.steps[2]);
	}
}

import { Injectable, signal } from '@angular/core';

interface UserState {
	selectedGroup?: string;
}

@Injectable({
	providedIn: 'root',
})
export class UserService {
	user = signal<UserState>({});

	getUser() {
		return this.user;
	}

	updateUser(data: UserState) {
		this.user.set({
			...this.user(),
			...data,
		});
	}

	clearUser() {
		this.user.set({});
	}
}

export const CUSTOMER_BUTTONS = [
	{
		icon: 'dvr',
		path: 'overview',
		label: 'Overview',
	},
	{
		icon: 'devices',
		path: 'devices',
		label: 'Devices',
	},
];

export const CONFIG_BUTTONS = [
	{
		icon: 'business_center',
		path: '/policies',
		label: 'Policies',
	},
	{
		icon: 'settings',
		path: '/configuration',
		label: 'Configuration',
	},
];

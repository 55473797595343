<section
	class="section-container"
	[class.section-container--collapsed]="collapsible() && isCollapsed()"
>
	<header class="flex justify-between items-center text-base">
		<h3 class="font-bold">{{ header() }}</h3>
		<ng-content select="[header-actions]"></ng-content>
	</header>

	<div class="content">
		<ng-content></ng-content>
	</div>

	@if (collapsible()) {
		<div class="collapse-button-cont">
			<button class="collapse-button" (click)="toggleCollapse()" matRipple>
				<mat-icon class="align-middle">arrow_drop_up</mat-icon>
			</button>
		</div>
	}
</section>

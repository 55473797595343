import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { NotificationService, NotificationVariation } from '@ui/notification/notification.service';

import { DeviceService } from '../../device/device.service';
import { DeviceAndroidPolicy, DeviceRestrictionUpdate } from '../devices.types';
import { AppCheckboxComponent } from './app-checkbox/app-checkbox.component';

@Component({
	selector: 'csd-app-restrictions',
	standalone: true,
	imports: [AppCheckboxComponent],
	templateUrl: './restrictions.component.html',
	styleUrl: './restrictions.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestrictionsComponent {
	deviceService = inject(DeviceService);
	notificationService = inject(NotificationService);

	device = this.deviceService.device;
	currentPolicy = signal<DeviceAndroidPolicy>({} as DeviceAndroidPolicy);

	changeSelection(value: boolean, index: number) {
		const policyAndroidSettings = [...this.device().policy.policyAndroidSettings];
		const policyToUpdate = policyAndroidSettings[index];

		policyToUpdate.value = value;
		this.currentPolicy.set(policyToUpdate);

		this.updateDevice({
			settingId: policyToUpdate.settingId,
			setting: {
				androidValue: policyToUpdate.setting.androidValue as string,
			},
			value: policyToUpdate.value,
		});
	}

	getDescription(differentThanGroup: boolean) {
		return differentThanGroup ? 'This policy differs from the group policy' : '';
	}

	updateDevice(data: DeviceRestrictionUpdate) {
		this.deviceService.updateDeviceRestriction(this.device().id, data).subscribe(() => {
			this.notificationService.open({
				variation: NotificationVariation.SUCCESS,
				message: `Policy <strong>${this.currentPolicy().setting.name}</strong> was updated.`,
			});
		});
	}
}

import { Component, input } from '@angular/core';

@Component({
	selector: 'csd-app-loader',
	standalone: true,
	imports: [],
	templateUrl: './loader.component.html',
	styleUrl: './loader.component.scss',
})
export class LoaderComponent {
	isLoading = input.required<boolean>();
	classList = input<string[]>(['w-32', 'lg:w-40', 'py-10']);
}

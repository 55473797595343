<div class="inside-menu">
	@for (item of itemsList(); track item.icon) {
		<a
			class="inside-menu__link"
			routerLink="{{ item.route }}"
			routerLinkActive="inside-menu__link--active"
		>
			<button class="flex flex-col items-center">
				<span class="text-3xl leading-none">
					<mat-icon inline>{{ item.icon }}</mat-icon>
				</span>
				<span class="link__label">{{ item.label }}</span>
			</button>
		</a>
	}
</div>

import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { DeviceTimerComponent } from './device-timer/device-timer.component';
import { SessionConfirmComponent } from './session-confirm/session-confirm.component';

export type ModalTypes = 'session-confirm' | 'device-timer';
type ModalComponent = Record<
	ModalTypes,
	ComponentType<SessionConfirmComponent | DeviceTimerComponent>
>;

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	dialog = inject(MatDialog);
	dialogRef?: MatDialogRef<unknown>;
	components: ModalComponent = {
		'session-confirm': SessionConfirmComponent,
		'device-timer': DeviceTimerComponent,
	};
	subscriptions$: Subscription[] = [];

	constructor() {}

	open(type: ModalTypes, data?: unknown, config?: MatDialogConfig) {
		this.dialog.closeAll();
		this.dialogRef = this.dialog.open(this.components[type], {
			closeOnNavigation: true,
			disableClose: type === 'session-confirm',
			autoFocus: 'dialog',
			...(config || {}),
			data,
		});
	}

	afterClose(callback?: (value: any) => void) {
		const afterCloseSubscription$ = this.dialogRef?.afterClosed().subscribe(callback);
		this.subscriptions$.push(afterCloseSubscription$ as Subscription);
	}

	close(data?: unknown) {
		this.dialogRef?.close(data);
		this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
		this.dialogRef = undefined;
	}
}

import { inject, Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { ModalService } from '@ui/modal/modal.service';
import { fromEvent, Subscription, throttleTime } from 'rxjs';

const oneMinute = 1000 * 60;

@Injectable({
	providedIn: 'root',
})
export class ActivityService {
	private authService = inject(AuthService);
	private modalService = inject(ModalService);

	timeAccumulator = 0;
	userInteractionTime = oneMinute * 5;
	inactivityTimeFrame = oneMinute * 60 * 2; // 2 hour
	warningMessageBeforeTime = this.inactivityTimeFrame - oneMinute * 5;
	intervalId?: NodeJS.Timeout = undefined;
	mouseMove$!: Subscription;
	keyDown$!: Subscription;
	logout$?: Subscription;

	private notifyTimeActivity() {
		if (this.modalService.dialogRef) {
			return;
		}

		this.timeAccumulator = 0;
	}

	private openCloseSessionModal() {
		this.modalService.open('session-confirm');

		this.modalService.afterClose((action: string) => {
			this.modalService.close();

			if (action === 'keep') {
				this.notifyTimeActivity();
				clearInterval(this.intervalId);
				this.startInterval();
			}

			if (action === 'close') {
				this.closeSession();
			}
		});
	}

	private startInterval() {
		this.intervalId = setInterval(() => {
			this.timeAccumulator += this.userInteractionTime;

			const isWarningTime =
				this.timeAccumulator >= this.warningMessageBeforeTime &&
				this.timeAccumulator < this.inactivityTimeFrame;

			if (isWarningTime && !this.modalService.dialogRef) {
				this.openCloseSessionModal();
			}

			if (this.timeAccumulator >= this.inactivityTimeFrame) {
				clearInterval(this.intervalId);
				this.closeSession();

				return;
			}
		}, this.userInteractionTime);
	}

	addListeners() {
		const mouseMoveEvent$ = fromEvent<MouseEvent>(document, 'mousemove');
		const keyDownEvent$ = fromEvent<KeyboardEvent>(document, 'keydown');

		this.mouseMove$ = mouseMoveEvent$.pipe(throttleTime(this.userInteractionTime)).subscribe(() => {
			this.notifyTimeActivity();
		});
		this.keyDown$ = keyDownEvent$.pipe(throttleTime(this.userInteractionTime)).subscribe(() => {
			this.notifyTimeActivity();
		});

		this.startInterval();
	}

	closeSession() {
		this.removeListeners();
		this.modalService.close();
		this.logout$ = this.authService.logout().subscribe();
	}

	removeListeners() {
		this.mouseMove$.unsubscribe();
		this.keyDown$.unsubscribe();
		this.logout$?.unsubscribe();
	}
}

<div class="relative">
	<csd-app-button
		classList="users-log__time text-lg font-bold text-layout-darkgray"
		[iconClassList]="logSidebar.opened ? 'transition-transform rotate-180' : 'transition-transform'"
		icon="chevron_left"
		type="text"
		>{{ currentTime() }}</csd-app-button
	>

	<mat-sidenav-container>
		<mat-sidenav
			class="user-sidenav"
			#logSidebar
			mode="over"
			position="end"
			fixedInViewport
			[style]="{ top: '100px', bottom: '50px' }"
		>
			<h4 class="bg-white rounded text-xs flex items-center justify-center">
				<mat-icon>arrow_drop_up</mat-icon><span>History</span>
			</h4>
		</mat-sidenav>
	</mat-sidenav-container>
</div>

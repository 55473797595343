import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	computed,
	effect,
	inject,
	ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '@ui/button/button.component';
import { LoaderComponent } from '@ui/loader/loader.component';

import { CustomersFromList } from './customer.types';
import { CustomersService } from './customers.service';

@Component({
	selector: 'csd-app-customers',
	standalone: true,
	templateUrl: './customers.component.html',
	styleUrl: './customers.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		ButtonComponent,
		LoaderComponent,
		MatIconModule,
		MatPaginatorModule,
		MatTableModule,
		RouterLink,
	],
})
export class CustomersComponent {
	@ViewChild(MatPaginator) paginator!: MatPaginator;

	private customersService = inject(CustomersService);
	private ref = inject(ChangeDetectorRef);

	displayedColumns = ['code', 'name', 'email', 'phone', 'edit'];
	isLoading = this.customersService.isLoading;
	data = this.customersService.getAllCustomers();

	dataSource = computed(() => {
		return new MatTableDataSource(this.data() as unknown as CustomersFromList[]);
	});

	constructor() {
		effect(() => {
			if (this.dataSource()?.data?.length) {
				this.updatePaginator();
			}
		});
	}

	setCustomer(customer: CustomersFromList) {
		this.customersService.setCustomerSelected(customer.customerId);
	}

	updatePaginator() {
		this.ref.detectChanges();
		this.dataSource().paginator = this.paginator;
	}
}

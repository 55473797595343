import { TitleCasePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, Scroll } from '@angular/router';
import { filter } from 'rxjs';

interface BreadcrumbItem {
	label: string;
	url: string;
}

@Component({
	selector: 'csd-app-breadcrumb',
	standalone: true,
	imports: [MatIconModule, RouterLink, TitleCasePipe],
	templateUrl: './breadcrumb.component.html',
	styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent implements OnInit {
	private router = inject(Router);
	private activatedRoute = inject(ActivatedRoute);

	breadcrumbs: BreadcrumbItem[] = [];

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((event) => {
					return (
						event instanceof NavigationEnd ||
						(event instanceof Scroll && event.routerEvent instanceof NavigationEnd)
					);
				}),
			)
			.subscribe((event) => {
				this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
			});
	}

	private createBreadcrumbs(
		route: ActivatedRoute,
		url: string = '',
		breadcrumbs: BreadcrumbItem[] = [],
	): BreadcrumbItem[] {
		const children: ActivatedRoute[] = route.children;

		if (children.length === 0) {
			return breadcrumbs;
		}

		for (const child of children) {
			const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
			if (routeURL !== '') {
				url += `/${routeURL}`;
			}

			const label = this.getLabel(child.snapshot.data);

			if (label) {
				breadcrumbs.push({ label, url });
			}

			return this.createBreadcrumbs(child, url, breadcrumbs);
		}

		return breadcrumbs;
	}

	private getLabel(data: any): string | null {
		if (typeof data?.breadcrumb === 'function') {
			return data.breadcrumb(data);
		}

		return data?.breadcrumb || null;
	}
}

<mat-sidenav-container>
	<mat-sidenav
		[mode]="isMobile() ? 'over' : 'side'"
		[opened]="shouldOpenSidenav()"
		fixedInViewport
		(openedChange)="onChangeSidenav($event)"
	>
		<csd-app-sidebar></csd-app-sidebar>
	</mat-sidenav>
	<mat-sidenav-content class="min-h-screen bg-white">
		<div class="content-mobile">
			<div class="content-mobile__menu">
				<button class="rounded-full p-4 flex items-center" matRipple (click)="openSideNav()">
					<mat-icon class="align-sub">menu</mat-icon>
				</button>
			</div>
			<div class="content-mobile__logo">
				<img class="h-10" src="../../../../assets/logo-emypeople.png" alt="eMyPeople logo" />
				<p class="tex-sm font-bold leading-none pt-2 pl-2">Dashboard</p>
			</div>
		</div>
		<div class="pl-5 py-2 pr-4 w-full">
			<div class="flex items-center w-full pb-10 min-h-28 flex-col sm:flex-row">
				<div class="grow transition-all overflow-hidden h-16 flex items-center w-full">
					<csd-app-customer-selection
						class="overflow-hidden transition-all"
						[class.w-full]="!isSearchOpen()"
						[class.w-0]="isSearchOpen()"
					></csd-app-customer-selection>
					<csd-app-search-area
						class="overflow-hidden transition-all"
						[class.w-full]="isSearchOpen()"
						[class.w-0]="!isSearchOpen()"
						(focusout)="searchFocusOut($event)"
						(optionSelected)="shouldOpenSearch.set(false)"
					></csd-app-search-area>
				</div>
				<div class="flex items-center">
					<div class="flex items-center min-h-12 sm:ml-7 sm:pl-3 sm:border-l border-layout-gray">
						<button class="rounded-full p-2 flex items-center" matRipple (click)="toggleSearch()">
							<mat-icon>search</mat-icon>
						</button>
					</div>
					<csd-app-users-log></csd-app-users-log>
				</div>
			</div>
			<div class="pb-8">
				<csd-app-breadcrumb></csd-app-breadcrumb>
			</div>
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>

import { Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
	selector: 'csd-app-notification',
	standalone: true,
	imports: [MatIconModule, MatSnackBarModule],
	templateUrl: './notification.component.html',
	styleUrl: './notification.component.scss',
})
export class NotificationComponent {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: { message: string },
		private snackBarRef: MatSnackBarRef<NotificationComponent>,
	) {}

	close() {
		this.snackBarRef.dismissWithAction();
	}
}

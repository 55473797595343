import { Component, computed, inject, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { CustomersService } from '@root/app/feature/customers/customers.service';
import { UserImageComponent } from '@ui/user-image/user-image.component';

@Component({
	selector: 'csd-app-customer-selection',
	standalone: true,
	templateUrl: './customer-selection.component.html',
	styleUrl: './customer-selection.component.scss',
	imports: [MatIconModule, MatRippleModule, UserImageComponent],
})
export class CustomerSelectionComponent {
	customerService = inject(CustomersService);
	router = inject(Router);

	customer = this.customerService.customer;
	userImg = signal<string>('');

	userName = computed<string>(() => {
		return this.customerService.customer()?.name || '';
	});

	removeSelection() {
		this.customerService.setCustomerSelected('');
		this.router.navigate(['/']);
	}
}

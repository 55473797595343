<mat-expansion-panel class="user-area" #expansionPanel>
	<mat-expansion-panel-header class="user-area__header">
		<mat-panel-title>
			<csd-app-user-image
				classList="mr-2 w-10 h-10"
				[image]="userImg"
				[user]="userName()"
			></csd-app-user-image>
			<div class="font-bold max-w-40">
				<p class="text-lg text-slate-700 truncate">{{ userName() }}</p>
				<p class="text-sm text-layout-darkgray truncate">{{ userRole() }}</p>
			</div>
		</mat-panel-title>
	</mat-expansion-panel-header>
	<div class="user-area__body">
		@for (group of groupList(); track group.id; let isFirst = $first) {
			<csd-app-button
				classList="text-left w-full mb-1.5"
				[active]="isActiveGroup(group.access, isFirst)"
				type="tonal"
				(click)="updateSelectedGroup(group.access)"
				>{{ getGroupName(group) }}</csd-app-button
			>
		}
		<csd-app-button classList="text-left w-full" icon="logout" type="text" (click)="logout()"
			>Log Out</csd-app-button
		>
		<hr class="separator" />
	</div>
</mat-expansion-panel>

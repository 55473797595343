import { A11yModule } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

import { ButtonComponent } from '../../button/button.component';

export interface ModalData {
	title?: string;
	closeLabel?: string;
	nextLabel?: string;
	[key: string]: unknown;
}

@Component({
	selector: 'csd-app-modal',
	standalone: true,
	imports: [MatDialogModule, ButtonComponent, A11yModule],
	templateUrl: './session-confirm.component.html',
	styleUrl: './session-confirm.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionConfirmComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data?: ModalData) {}
}

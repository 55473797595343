import { NgClass } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { MatOption, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MtxPopoverModule, MtxPopoverPosition } from '@ng-matero/extensions/popover';
import { DeviceAppPolicy, DeviceAppsUpdate } from '@root/app/feature/devices/apps/apps.types';

import { ACTIVE_OPTIONS, INTERNET_CONFIG_OPTIONS } from './app-item.constants';
import { AppPolicyChangeOptions, InternetConfig } from './app-item.types';

@Component({
	selector: 'csd-app-app-item',
	standalone: true,
	imports: [MatIconModule, MatRippleModule, MatSelectModule, MtxPopoverModule, NgClass],
	templateUrl: './app-item.component.html',
	styleUrl: './app-item.component.scss',
})
export class AppItemComponent {
	position: MtxPopoverPosition = ['above', 'after'];
	activeOptions = ACTIVE_OPTIONS;

	appsStatus = input<Record<string, string>>({});
	data = input<DeviceAppPolicy>();
	classList = input<string | string[]>('');

	changedConfig = output<DeviceAppsUpdate>();

	internetConfig = InternetConfig;
	internetConfigOptions = INTERNET_CONFIG_OPTIONS;
	appStatusOptions = computed(() => {
		return Object.keys(this.appsStatus()).map((el) => ({
			label: (this.appsStatus() as unknown as Record<string, string>)?.[el],
			value: el,
		}));
	});

	compareSimpleValues(a: MatOption, b: MatOption) {
		return a == b;
	}

	getLogo(link?: string) {
		return link ?? '../../../assets/android-default-app-icon.png';
	}

	getRandomError() {
		return Math.round(Math.random());
	}

	onSelectChange(policy: AppPolicyChangeOptions, value: number) {
		if (!this.data()) {
			return;
		}

		const data = this.data() as DeviceAppPolicy;
		const dataToEmit = {
			appId: data.appId,
			packageName: data.appResponse.packageName,
			status: data.status,
			internetConfig: data.internetConfig,
		};

		this.changedConfig.emit({ ...dataToEmit, [policy]: value });
	}
}

import { CdkDrag, CdkDragHandle, CdkDragPlaceholder, CdkDropList } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { CustomersService } from '@root/app/feature/customers/customers.service';
import { ButtonComponent } from '@ui/button/button.component';
import { SectionComponent } from '@ui/section/section.component';

import { SortableListComponent } from './sortable-list/sortable-list.component';

@Component({
	selector: 'csd-app-firewalls',
	standalone: true,
	imports: [
		ButtonComponent,
		CdkDrag,
		CdkDragHandle,
		CdkDragPlaceholder,
		CdkDropList,
		SectionComponent,
		MatIconModule,
		MatRippleModule,
		SortableListComponent,
	],
	templateUrl: './firewalls.component.html',
	styleUrl: './firewalls.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirewallsComponent {
	customersService = inject(CustomersService);

	customer = this.customersService.customer;

	itemsList = [
		{
			name: 'SLW Custom 1',
			owner: '-AFFSME0',
			id: '1',
		},
		{
			name: 'SLW Custom 2',
			owner: 'pool',
			id: '2',
		},
		{
			name: 'SLW Custom 3',
			owner: 'global',
			id: '3',
		},
		{
			name: 'SLW Custom 4',
			owner: '-AFFSME0',
			id: '4',
		},
		{
			name: 'SLW Custom 5',
			owner: '-AFFSME0',
			id: '5',
		},
		{
			name: 'SLW Custom 6',
			owner: '-AFFSME0',
			id: '6',
		},
	];
}

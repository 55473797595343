<div class="overview-comp">
	<div class="overview-comp__content grow">
		<csd-app-loader [isLoading]="isLoading()">
			@if (!customer()?.code) {
				<csd-app-empty-state>
					<span
						>We couldn't find a customer with code <strong>{{ customer()?.code }}</strong></span
					>
					<ng-content ngProjectAs="empty-state-message">
						<span
							>Search by name instead or go to the <a routerLink="/">customers list</a> page.</span
						>
					</ng-content>
				</csd-app-empty-state>
			} @else {
				<h4 class="section-header">My Information</h4>
				<p class="text-2xl text-layout-darkgray font-bold flex items-center pb-4">
					{{ customer()?.name }}
					<span class="bg-layout-sky p-2 ml-5 font-normal text-base rounded">{{
						customer()?.code
					}}</span>
				</p>
				<div class="additional-information space-y-2.5">
					<p>
						<mat-icon inline class="w-6 align-top material-symbols-filled">location_on</mat-icon>
						{{ customer()?.streetAddress }}
					</p>
					<p>
						<mat-icon inline class="w-6 align-top material-symbols-filled">mail</mat-icon>
						{{ customer()?.email }}
					</p>
					<p>
						<mat-icon inline class="w-6 align-top material-symbols-filled">phone_enabled</mat-icon>
						{{ customer()?.officePhone }}
					</p>
				</div>
				<h4 class="section-header pt-16">Notification Preferences</h4>
				<form [formGroup]="form" class="space-y-4">
					<p>
						<mat-slide-toggle
							formControlName="dataUsageNotices"
							(change)="dataUsageChange($event)"
							class="mat-slide-toggle-small"
							hideIcon
						>
							<span class="pl-5 text-base">Data Usage Notices</span>
						</mat-slide-toggle>
					</p>
					<p class="time-selector pt-5" [class.opacity-50]="!form.value.dataUsageNotices">
						<mat-select
							class="text-center"
							[class.select-error]="form.controls.frequencyInDays.errors?.['min']"
							formControlName="frequencyInDays"
							hideSingleSelectionIndicator
						>
							<mat-select-trigger>
								{{ form.value.frequencyInDays }}
							</mat-select-trigger>
							@for (days of recurringDays; track days.label) {
								<mat-option class="option-small" [value]="days.value">{{ days.label }}</mat-option>
							}
						</mat-select>
						<span class="grow pl-4">Days between recurring reminders</span>
					</p>
					<p class="time-selector" [class.opacity-50]="!form.value.dataUsageNotices">
						<mat-select
							class="text-center"
							[class.select-error]="form.controls.spanBeforeAlertInDays.errors?.['min']"
							formControlName="spanBeforeAlertInDays"
							hideSingleSelectionIndicator
						>
							<mat-select-trigger>
								{{ form.value.spanBeforeAlertInDays }}
							</mat-select-trigger>
							@for (days of daysAlerted; track days.label) {
								<mat-option class="option-small" [value]="days.value">{{ days.label }}</mat-option>
							}
						</mat-select>
						<span class="grow pl-4">
							Days before alerts start
							<mat-icon
								inline
								#tooltip="matTooltip"
								matTooltip="Alerts can be muted after the plan starts"
								class="cursor-pointer align-bottom"
							>
								info
							</mat-icon>
						</span>
					</p>
				</form>
			}
		</csd-app-loader>
	</div>
	<div class="overview-comp__notes max-w-lg"></div>
</div>

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
	provideRouter,
	Routes,
	withComponentInputBinding,
	withInMemoryScrolling,
	withRouterConfig,
} from '@angular/router';
import { AbstractSecurityStorage, authInterceptor, provideAuth } from 'angular-auth-oidc-client';

import { authConfig } from './auth/auth.config';
import { LocalStorageManagerService } from './local-storage-manager/local-storage-manager.service';
import { MyErrorHandler } from './logger/logger.handler';

export interface CoreOptions {
	routes: Routes;
}

export function provideCore({ routes }: CoreOptions) {
	return [
		provideAnimationsAsync(),
		provideHttpClient(withInterceptors([authInterceptor()])),
		provideAuth(authConfig),
		{
			provide: AbstractSecurityStorage,
			useClass: LocalStorageManagerService,
		},
		provideRouter(
			routes,
			withRouterConfig({
				onSameUrlNavigation: 'reload',
			}),
			withComponentInputBinding(),
			withInMemoryScrolling({
				anchorScrolling: 'enabled',
				scrollPositionRestoration: 'enabled',
			}),
		),
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: {
				verticalPosition: 'bottom',
				horizontalPosition: 'center',
			},
		},
		{
			provide: ErrorHandler,
			useClass: MyErrorHandler,
		},
	];
}

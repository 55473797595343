import {
	CdkDrag,
	CdkDragDrop,
	CdkDragHandle,
	CdkDragPlaceholder,
	CdkDropList,
	moveItemInArray,
} from '@angular/cdk/drag-drop';
import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TagComponent } from '@ui/tag/tag.component';

@Component({
	selector: 'csd-app-sortable-list',
	standalone: true,
	imports: [
		CdkDrag,
		CdkDragHandle,
		CdkDragPlaceholder,
		CdkDropList,
		MatIconModule,
		MatRippleModule,
		TagComponent,
		UpperCasePipe,
	],
	templateUrl: './sortable-list.component.html',
	styleUrl: './sortable-list.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortableListComponent {
	ownerColorVariation: Record<string, string> = {
		user: 'bg-sky-800 text-white',
		pool: 'bg-orange-500 text-white',
		global: 'bg-green-600 text-white',
	};
	itemsList = input.required<Record<string, unknown>[]>();
	innerSort = signal<Record<string, unknown>[]>([]);
	list = computed(() => (this.innerSort.length ? this.innerSort() : this.itemsList()));

	drop(event: CdkDragDrop<number[]>) {
		if (!this.innerSort().length) {
			this.innerSort.set(this.itemsList());
		}

		moveItemInArray(this.innerSort(), event.previousIndex, event.currentIndex);
	}

	getColorVariation(owner: unknown) {
		return this.ownerColorVariation[owner as string] || this.ownerColorVariation['user'];
	}

	toString(value: any) {
		return `${value}`;
	}
}

<div>
	<csd-app-loader [isLoading]="loadingDevice()">
		<form [formGroup]="form" (submit)="onSubmit($event)">
			<h2 mat-dialog-title>Temporary Policy Timer</h2>
			<mat-dialog-content>
				<p>
					When the timer is active, any changes made to the apps or restrictions will be reverted
					when the timer is finished.
				</p>
				<p class="py-5 max-w-[50%]">
					<mat-select
						formControlName="timer"
						hideSingleSelectionIndicator
						placeholder="Select timer"
						class="timer-dropdown"
					>
						@for (item of timerOptions; track item.value) {
							<mat-option [value]="item.value" class="option-small">{{ item.label }}</mat-option>
						}
					</mat-select>
				</p>
			</mat-dialog-content>
			<mat-dialog-actions align="end" class="space-x-4">
				<csd-app-button size="small" mat-dialog-close classList="min-w-28">Cancel</csd-app-button>
				<csd-app-button
					size="small"
					variation="secondary"
					classList="min-w-28"
					[disabled]="!form.valid || loadingSubmit()"
					htmlType="submit"
				>
					Start
					@if (loadingSubmit()) {
						<mat-icon inline class="animate-spin align-bottom px-1">progress_activity</mat-icon>
					}
				</csd-app-button>
			</mat-dialog-actions>
		</form>
	</csd-app-loader>
</div>

@if (isLoading()) {
  <div class="w-full">
    <img
      class="mx-auto"
      [class]="classList()"
      src="../../../../assets/circle-loader.gif"
      alt="loading...please wait"
    />
  </div>
} @else {
  <ng-content></ng-content>
}

import { ErrorHandler, inject } from '@angular/core';
import { NotificationService, NotificationVariation } from '@ui/notification/notification.service';

export class MyErrorHandler implements ErrorHandler {
	notificationService = inject(NotificationService);

	handleError(error: Error | any) {
		this.notificationService.open({
			variation: NotificationVariation.ERROR,
			message: error?.message,
		});

		console.error(error);
	}
}

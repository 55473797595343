import { Component, input, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'csd-app-section',
	standalone: true,
	imports: [MatIconModule, MatRippleModule],
	templateUrl: './section.component.html',
	styleUrl: './section.component.scss',
})
export class SectionComponent {
	header = input.required<string>();
	collapsible = input<boolean>(false);

	isCollapsed = signal<boolean>(true);

	toggleCollapse() {
		this.isCollapsed.set(!this.isCollapsed());
	}
}

<div class="space-y-5">
	<csd-app-section header="{{ customer()?.code }} Firewall Policies" [collapsible]="true">
		<ng-container header-actions>
			<csd-app-button size="small">
				<span class="font-bold text-lg"><mat-icon inline class="align-bottom">add</mat-icon></span>
			</csd-app-button>
		</ng-container>

		<csd-app-sortable-list [itemsList]="itemsList"></csd-app-sortable-list>
	</csd-app-section>

	<csd-app-section header="All Firewall Policies" [collapsible]="true">
		<ng-container header-actions>
			<csd-app-button size="small">
				<span class="font-bold text-lg"><mat-icon inline class="align-bottom">add</mat-icon></span>
			</csd-app-button>
		</ng-container>

		<csd-app-sortable-list [itemsList]="itemsList"></csd-app-sortable-list>
	</csd-app-section>
</div>

import { Routes } from '@angular/router';
import { DeviceLayoutComponent } from '@layout/device-layout/device-layout.component';

import { deviceResolver } from '../device/device.resolver';
import { AppsComponent } from './apps/apps.component';
import { DevicesComponent } from './devices.component';
import { InternetComponent } from './internet/internet.component';
import { RestrictionsComponent } from './restrictions/restrictions.component';
import { StatsComponent } from './stats/stats.component';

const baseTitle = '| Devices | eMyPeople';

export default <Routes>[
	{
		path: '',
		component: DevicesComponent,
		data: { breadcrumb: '' },
	},
	{
		path: 'add-device',
		loadComponent: () => import('@feature/devices/add-device/add-device.component'),
		title: `Add Device ${baseTitle}`,
		data: { breadcrumb: 'Add Device' },
	},
	{
		path: ':deviceName',
		component: DeviceLayoutComponent,
		resolve: { device: deviceResolver },
		data: { breadcrumb: (data: any) => data?.device?.name },
		children: [
			{
				path: '',
				redirectTo: 'stats',
				pathMatch: 'prefix',
			},
			{
				path: 'stats',
				component: StatsComponent,
				title: `Stats ${baseTitle}`,
				data: { breadcrumb: 'Stats' },
			},
			{
				path: 'internet',
				component: InternetComponent,
				title: `Internet ${baseTitle}`,
				data: { breadcrumb: 'Internet' },
			},
			{
				path: 'apps',
				component: AppsComponent,
				title: `Apps ${baseTitle}`,
				data: { breadcrumb: 'Apps' },
			},
			{
				path: 'restrictions',
				component: RestrictionsComponent,
				title: `Restrictions ${baseTitle}`,
				data: { breadcrumb: 'Restrictions' },
			},
		],
	},
];

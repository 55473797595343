import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	inject,
	signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AppItemComponent } from '@ui/app-item/app-item.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { HeaderDividerComponent } from '@ui/header-divider/header-divider.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { NotificationService, NotificationVariation } from '@ui/notification/notification.service';

import { DeviceService } from '../../device/device.service';
import { DevicesService } from '../devices.service';
import { AppsService } from './apps.service';
import { DeviceAppPolicy, DeviceAppsUpdate } from './apps.types';

@Component({
	selector: 'csd-app-apps',
	standalone: true,
	imports: [
		AppItemComponent,
		EmptyStateComponent,
		FormsModule,
		HeaderDividerComponent,
		LoaderComponent,
		MatIconModule,
		MatInputModule,
	],
	templateUrl: './apps.component.html',
	styleUrl: './apps.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsComponent {
	appsService = inject(AppsService);
	deviceService = inject(DeviceService);
	devicesService = inject(DevicesService);
	notificationService = inject(NotificationService);

	searchText = '';

	launcherFilterApps = signal<DeviceAppPolicy[]>([]);
	systemFilterApps = signal<DeviceAppPolicy[]>([]);
	uninstalledFilterApps = signal<DeviceAppPolicy[]>([]);
	appOptions = this.devicesService.appOptions;
	isLoading = this.deviceService.isLoading;
	device = this.deviceService.device;

	launcherApps = computed(() => {
		const { appTypes } = this.appOptions() || { appTypes: {} };
		const normalAppsId = Object.values(appTypes).findIndex((el) => el === 'Launcher');

		return this.device().policy.policyAppJoins.filter(
			(el) => el.appResponse.type === normalAppsId && el.installed,
		);
	});

	systemApps = computed(() => {
		const { appTypes } = this.appOptions() || { appTypes: {} };
		const systemAppsId = Object.values(appTypes).findIndex((el) => el === 'System');

		return this.device().policy.policyAppJoins.filter((el) => el.appResponse.type === systemAppsId);
	});

	uninstalledApps = computed(() => {
		const { appTypes } = this.appOptions() || { appTypes: {} };
		const normalAppsId = Object.values(appTypes).findIndex((el) => el === 'Launcher');

		return this.device().policy.policyAppJoins.filter(
			(el) => el.appResponse.type === normalAppsId && !el.installed,
		);
	});

	constructor() {
		effect(
			() => {
				if (this.device()) {
					this.launcherFilterApps.set(this.launcherApps());
					this.systemFilterApps.set(this.systemApps());
					this.uninstalledFilterApps.set(this.uninstalledApps());
				}
			},
			{ allowSignalWrites: true },
		);
	}

	private filterPackage(item: DeviceAppPolicy, value: string) {
		const matchRegex = new RegExp(value, 'gi');
		const { appResponse } = item;

		return appResponse.name.match(matchRegex) || appResponse.packageName.match(matchRegex);
	}

	clearSearch() {
		this.searchText = '';
		this.filterApps(this.searchText);
	}

	filterApps(value: string) {
		if (value) {
			this.launcherFilterApps.set(
				this.launcherApps().filter((item) => this.filterPackage(item, value)),
			);
			this.systemFilterApps.set(
				this.systemApps().filter((item) => this.filterPackage(item, value)),
			);
			this.uninstalledFilterApps.set(
				this.uninstalledApps().filter((item) => this.filterPackage(item, value)),
			);

			return;
		}

		this.launcherFilterApps.set(this.launcherApps());
		this.systemFilterApps.set(this.systemApps());
		this.uninstalledFilterApps.set(this.uninstalledApps());
	}

	updateStatus(newAppData: DeviceAppsUpdate, app: DeviceAppPolicy) {
		this.appsService.updateStatus(this.device().id, newAppData).subscribe({
			next: () => {
				this.notificationService.open({
					variation: NotificationVariation.SUCCESS,
					message: `App policy for <strong>${app.appResponse.name}</strong> was updated`,
				});
			},
			error: (err) => {
				throw new Error(err.error?.title || err.message);
			},
		});
	}
}

import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@root/app/core/api/api.service';
import { tap } from 'rxjs';

import { UserService } from '../../user/user.service';
import { DeviceAppsUpdate } from './apps.types';

@Injectable({
	providedIn: 'root',
})
export class AppsService {
	private apiService = inject(ApiService);
	private userService = inject(UserService);

	isLoading = signal<boolean>(false);

	updateStatus(deviceId: string, data: DeviceAppsUpdate) {
		const { selectedGroup } = this.userService.user();

		this.isLoading.set(true);

		return this.apiService
			.put<void>(`/android/device/appstatus/${deviceId}`, data, {
				params: { access: selectedGroup },
			})
			.pipe(
				tap(() => {
					this.isLoading.set(false);
				}),
			);
	}
}

<div class="search-area">
	<form class="search-form-field">
		<input
			type="text"
			class="w-full h-10 border-none bg-transparent focus:outline-none px-0.5"
			placeholder="Search customer"
			aria-label="Number"
			matInput
			[formControl]="searchControl"
			[matAutocomplete]="auto"
			(focusout)="searchFocusOut()"
		/>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="autocompleteSelected($event)">
			@for (option of options(); track option) {
				<mat-option [value]="option">
					<div class="flex flex-col">
						<span class="text-sm text-layout-darkgray">{{ option['name'] }}</span>
						<span class="text-xs text-layout-lightgray">{{ option['searchTerm'] }}</span>
					</div>
				</mat-option>
			}
		</mat-autocomplete>
	</form>
	<p class="m-0 text-lg">
		@if (isLoading()) {
			<mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner>
		} @else {
			<mat-icon inline [matTooltip]="searchTooltip" class="cursor-pointer align-bottom"
				>info</mat-icon
			>
		}
	</p>
</div>

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { DeviceService } from './device.service';

export const deviceResolver: ResolveFn<boolean> = (route) => {
	const deviceService = inject(DeviceService);

	return deviceService.getDevice(route.paramMap.get('deviceName') as string);
};

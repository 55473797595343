import { Component, effect, EffectRef, inject, input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@core/api/api.service';
import { AuthService } from '@core/auth/auth.service';
import { AnimateEllipsisComponent } from '@ui/animate-ellipsis/animate-ellipsis.component';

@Component({
	selector: 'csd-app-login-callback',
	standalone: true,
	imports: [AnimateEllipsisComponent],
	templateUrl: './login-callback.component.html',
})
export class LoginCallbackComponent implements OnInit, OnDestroy {
	private apiService = inject(ApiService);
	private authService = inject(AuthService);
	private router = inject(Router);

	statusText: string = 'Loading';
	userData = this.authService.userData;

	code = input<string>('');
	state = input<string>('');

	effectRef!: EffectRef;

	constructor() {
		this.effectRef = effect(() => {
			if (this.userData()?.sub) {
				this.retrieveListContent();
			}
		});
	}

	ngOnInit() {
		this.isValidSession();
	}

	ngOnDestroy(): void {
		this.effectRef.destroy();
	}

	isValidSession() {
		if (this.code() && this.state()) {
			return;
		}

		this.router.navigate(['/authentication/login']);
	}

	retrieveListContent() {
		const userSub = this.userData().sub;

		this.statusText = 'Authorizing';
		this.apiService.get(`/access/listcontent/${userSub}`).subscribe(this.validateList.bind(this));
	}

	validateList(content: unknown) {
		const isEmptyList = !content || (Array.isArray(content) && content.length === 0);

		if (isEmptyList) {
			this.router.navigate(['/onboarding'], { replaceUrl: true });
		}

		this.validateRedirectRoute();
	}

	validateRedirectRoute() {
		const isLoginCallback = this.router.url.match(/login-callback/gi);

		if (!isLoginCallback) {
			return;
		}

		this.router.navigate(['/']);
	}
}

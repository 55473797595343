import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@root/app/core/api/api.service';

import { OptionSelection } from './options/options.component';

@Injectable({
	providedIn: 'root',
})
export class ConnectService {
	constructor(
		private apiService: ApiService,
		private router: Router,
	) {}

	checkEmail(email: string, callback: () => void) {
		const parsedEmail = this.parseUrlString(email);

		this.apiService.get(`/setup/emailexists/${parsedEmail}`).subscribe({
			next: (exists) => {
				if (!exists) {
					this.router.navigate(['/onboarding/new-customer']);
					return;
				}

				callback();
			},
		});
	}

	getNumbersList<T>(email: string, callback: (data: T) => void) {
		const parsedEmail = this.parseUrlString(email);

		this.apiService.get(`/setup/getnumberlist/${parsedEmail}`).subscribe({
			next: (numbersList) => {
				callback((numbersList ?? []) as T);
			},
		});
	}

	requestSecurityCode(email: string, options: OptionSelection) {
		const parsedEmail = this.parseUrlString(email);

		return this.apiService.get(`/setup/sendsecuritycode/${parsedEmail}`, { params: { ...options } });
	}

	validateSecurityCode(params: { sub: string; email: string; code: string }) {
		return this.apiService.get(`/setup/linkaccount`, {
			params: {
				userSub: params['sub'],
				email: params['email'],
				twoFactor: params['code'],
			},
		});
	}

	private parseUrlString(text: string) {
		return encodeURIComponent(text);
	}
}

import { inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ApiService } from '@root/app/core/api/api.service';
import { catchError, tap } from 'rxjs';

import { AppOptions, SubscriptionGroup } from './devices.types';

@Injectable({
	providedIn: 'root',
})
export class DevicesService {
	private apiService = inject(ApiService);

	isLoading = signal<boolean>(false);
	appOptions = toSignal(this.apiService.get<AppOptions>('/android/apps/appoptions'));
	devices = signal<SubscriptionGroup[]>([]);

	selectedWorkOrder = signal<number>(0);

	getDevices(customerId: string) {
		this.isLoading.set(true);

		return this.apiService
			.get<SubscriptionGroup[]>(`/customer/getservicesbyworkorder/${customerId}`)
			.pipe(
				tap((data) => {
					this.devices.set(data);
					this.isLoading.set(false);
				}),
				catchError(() => {
					this.isLoading.set(false);
					this.devices.set([]);
					throw new Error('Error getting devices list');
				}),
			);
	}

	setWorkOrder(workOrder: number) {
		this.selectedWorkOrder.set(workOrder);
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private baseApi = environment.baseApi;
	private mergeOptions(options: any) {
		return {
			observe: 'body',
			responsType: 'json',
			...(options || {}),
		};
	}

	constructor(private httpClient: HttpClient) {}

	get<T>(link: string, options?: any) {
		return this.httpClient.get(
			`${this.baseApi}${link}`,
			this.mergeOptions(options),
		) as Observable<T>;
	}

	post<T>(link: string, body: unknown, options?: any) {
		return this.httpClient.post(
			`${this.baseApi}${link}`,
			body,
			this.mergeOptions(options),
		) as Observable<T>;
	}

	put<T>(link: string, body: unknown, options?: any) {
		return this.httpClient.put(
			`${this.baseApi}${link}`,
			body,
			this.mergeOptions(options),
		) as Observable<T>;
	}
}

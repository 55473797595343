import { InternetConfig } from './app-item.types';

export const ACTIVE_OPTIONS = [
	{
		label: 'Active',
		value: 2,
	},
	{
		label: 'Disable',
		value: 5,
	},
	{
		label: 'Hidden',
		value: 3,
	},
];

export const INTERNET_CONFIG_OPTIONS = Object.keys(InternetConfig)
	.filter((el) => !Number.isNaN(+el))
	.map((el: string) => ({
		label: InternetConfig[+el],
		value: el,
	}));

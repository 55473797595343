import { Component, effect, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { debounceTime, Subscription } from 'rxjs';

import { CustomersService } from '../customers/customers.service';
import { DAYS_ALERTED, RECURRING_DAYS } from './constants';

type FormDataPreferences = {
	dataUsageNotices?: boolean;
	frequencyInDays?: number;
	spanBeforeAlertInDays?: number;
};

@Component({
	selector: 'csd-app-overview',
	standalone: true,
	templateUrl: './overview.component.html',
	styleUrl: './overview.component.scss',
	imports: [
		EmptyStateComponent,
		LoaderComponent,
		MatIconModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatTooltipModule,
		ReactiveFormsModule,
		RouterLink,
	],
})
export class OverviewComponent implements OnInit, OnDestroy {
	private customersService = inject(CustomersService);
	private fb = inject(FormBuilder);
	private router = inject(Router);

	daysAlerted = DAYS_ALERTED;
	recurringDays = RECURRING_DAYS;

	customer = this.customersService.customer;
	isLoading = this.customersService.isLoading;
	subscriptions$: Subscription[] = [];
	form = this.fb.group({
		dataUsageNotices: [true, [Validators.required]],
		frequencyInDays: [1, [Validators.required, Validators.min(1)]],
		spanBeforeAlertInDays: [1, [Validators.required, Validators.min(1)]],
	});

	constructor() {
		effect(() => {
			const { dataNotificationPreferences } = this.customer() || {};

			if (dataNotificationPreferences) {
				this.form.patchValue({ ...dataNotificationPreferences });
			}
		});
	}

	get frecuencyControl() {
		return this.form.get('frequencyInDays') as FormControl;
	}

	get alertInDaysControl() {
		return this.form.get('spanBeforeAlertInDays') as FormControl;
	}

	ngOnInit(): void {
		const formSubs$ = this.form.valueChanges
			.pipe(debounceTime(500))
			.subscribe(this.updateChanges.bind(this));

		this.subscriptions$.push(formSubs$);
	}

	ngOnDestroy(): void {
		this.subscriptions$.forEach((subs$) => {
			subs$.unsubscribe();
		});
	}

	dataUsageChange(event: MatSlideToggleChange) {
		const isChecked = event.checked;

		if (isChecked) {
			this.frecuencyControl.setValue(1);
			this.alertInDaysControl.setValue(1);
		}
	}

	updateControlStatus(formValue: FormDataPreferences) {
		const { dataUsageNotices } = formValue;
		const action = dataUsageNotices ? 'enable' : 'disable';
		const emitEvent = { emitEvent: false };

		this.frecuencyControl[action](emitEvent);
		this.alertInDaysControl[action](emitEvent);
	}

	updateChanges(formValue: unknown) {
		const { code } = this.customer() || {};
		const value = formValue as FormDataPreferences;

		if (!code || !this.form.dirty || !this.form.valid) {
			return;
		}

		const data: Record<string, unknown> = {
			...this.customer(),
			dataNotificationPreferences: {
				...value,
				frequencyInDays: value.dataUsageNotices ? value.frequencyInDays : 0,
				spanBeforeAlertInDays: value.dataUsageNotices ? value.spanBeforeAlertInDays : 0,
			},
		};

		const update$ = this.customersService.updateCustomer(code, data).subscribe();

		this.subscriptions$.push(update$);
	}

	validateCustomer(customerId: string) {
		if (!this.customer()) {
			this.customersService.setCustomerSelected(customerId);
		}
	}
}

import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent } from '@ui/button/button.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { InlineMessageComponent } from '@ui/inline-message/inline-message.component';
import { LoaderComponent } from '@ui/loader/loader.component';

import { ModalService } from '../../ui/modal/modal.service';
import { CustomersService } from '../customers/customers.service';
import { DevicesService } from './devices.service';
import { DeviceAndroid, DeviceRouter } from './devices.types';

@Component({
	selector: 'csd-app-devices',
	standalone: true,
	templateUrl: './devices.component.html',
	styleUrl: './devices.component.scss',
	imports: [
		ButtonComponent,
		EmptyStateComponent,
		InlineMessageComponent,
		LoaderComponent,
		MatDividerModule,
		MatIconModule,
		MatRippleModule,
		MatTableModule,
		RouterLink,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesComponent {
	customersService = inject(CustomersService);
	devicesService = inject(DevicesService);
	modalService = inject(ModalService);
	router = inject(Router);

	mdmColumns = ['os', 'deviceName', 'licenseNumber', 'timer'];
	connectionsColumns = ['device', 'identifier', 'timer'];
	customer = this.customersService.customer;
	isLoading = this.devicesService.isLoading;
	groupSubscriptions = this.devicesService.devices;
	areDevicesLoaded = signal<boolean>(false);

	constructor() {
		effect(
			() => {
				const { code } = this.customer() || {};

				if (!code) {
					this.areDevicesLoaded.set(false);
					return;
				}

				this.devicesService.getDevices(code).subscribe(() => {
					this.areDevicesLoaded.set(true);
				});
			},
			{ allowSignalWrites: true },
		);
	}

	openDevice(row: DeviceRouter | DeviceAndroid) {
		this.devicesService.setWorkOrder(row.workOrder);
		this.router.navigate([this.customer()?.code, 'devices', row.id]);
	}

	onCloseModal(data?: { success: boolean }) {
		if (!data?.success) {
			return;
		}

		this.devicesService.getDevices(this.customer()?.code as string).subscribe();
	}

	openTimer(event: Event, row: DeviceAndroid) {
		event.preventDefault();
		event.stopPropagation();

		const modalConfig = { maxWidth: '550px' };

		this.modalService.open('device-timer', row, modalConfig);
		this.modalService.afterClose(this.onCloseModal.bind(this));
	}
}

<div class="notification__label" matSnackBarLabel>
	<div class="flex items-center text-xl">
		<mat-icon class="min-w-fit">info</mat-icon>
		<span class="notification__message" [innerHTML]="data.message"></span>
	</div>
</div>
<div matSnackBarActions>
	<button mat-button matSnackBarAction (click)="close()" class="text-base leading-4 px-1">
		<mat-icon inline>close</mat-icon>
	</button>
</div>

import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { environment } from '@root/environments/environment';
import { ButtonComponent } from '@ui/button/button.component';
import moment, { Moment } from 'moment';

@Component({
	selector: 'csd-app-users-log',
	standalone: true,
	templateUrl: './users-log.component.html',
	styleUrl: './users-log.component.scss',
	imports: [ButtonComponent, MatIconModule, MatSidenavModule],
})
export class UsersLogComponent implements OnInit, OnDestroy {
	currentDate = signal<Moment>(moment());
	currentTime = signal<string>('');
	intervalId?: NodeJS.Timeout = undefined;

	constructor(private httpClient: HttpClient) {}

	ngOnInit(): void {
		this.httpClient
			.get(`//worldtimeapi.org/api/timezone/${environment.timezoneCity}`)
			.subscribe(this.setTimeFromServer.bind(this));
	}

	ngOnDestroy(): void {
		clearInterval(this.intervalId);
	}

	setTimeFromServer(response: Object) {
		const { datetime } = (response as Record<string, string>) || {};
		const time = formatDate(datetime, 'h:mm a', 'en-US');

		this.currentDate.set(moment(datetime));
		this.currentTime.set(time);
		this.updateTime();
	}

	updateTime() {
		const minuteInMili = 1000 * 60;

		this.intervalId = setInterval(() => {
			this.currentDate.set(this.currentDate().add(1, 'm'));
			this.currentTime.set(this.currentDate().format('h:mm a'));
		}, minuteInMili);
	}
}

export const COMMAND_BUTTONS = [
	{
		icon: 'lock',
		label: 'Lock',
		command: 'lock',
	},
	{
		icon: 'autorenew',
		label: 'Restart',
		command: 'restart',
	},
	{
		icon: 'verified_user',
		label: 'Refresh Certs',
		command: 'refreshCerts',
	},
	{
		icon: 'lock_open_right',
		label: 'Clear Screen Lock',
		command: 'clearScreenLock',
	},
];

export const UPDATES_CHANNELS = [
	{
		label: 'Stable',
		value: '2',
	},
	{
		label: 'Beta',
		value: '1',
	},
	{
		label: 'Alpha',
		value: '0',
	},
];

export const ANDROID_COMMANDS: Record<string, number> = {
	lock: 0,
	restart: 2,
	refreshCerts: 4,
	clearScreenLock: 7,
};

import { Component, effect, inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NotificationService, NotificationVariation } from '@ui/notification/notification.service';
import { catchError, debounceTime } from 'rxjs';

import { DeviceService } from '../../device/device.service';
import { VPN_FILTER_OPTIONS } from './internet.constants';
import { StepComponent } from './step/step.component';

@Component({
	selector: 'csd-app-internet',
	standalone: true,
	imports: [
		MatIconModule,
		MatInputModule,
		MatRippleModule,
		MatSelectModule,
		MatSlideToggleModule,
		ReactiveFormsModule,
		StepComponent,
	],
	templateUrl: './internet.component.html',
	styleUrl: './internet.component.scss',
})
export class InternetComponent implements OnInit {
	deviceService = inject(DeviceService);
	fb = inject(FormBuilder);
	notificationService = inject(NotificationService);

	filterOptions = VPN_FILTER_OPTIONS;
	form = this.fb.group({
		vpn: true,
		browserUrl: '',
		proxyPacUrl: '',
		vpnProxy: undefined,
	});

	constructor() {
		effect(this.updateForm.bind(this), { allowSignalWrites: true });
	}

	ngOnInit(): void {
		this.form.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
			if (this.form.pristine) {
				return;
			}

			const device = this.deviceService.device();
			const newData = {
				name: device.name,
				group: device.group.id,
				uninstallKey: device.uninstallKey,
				proxyConfigURL: value.vpn ? '' : value.proxyPacUrl,
				proxyExceptions: device.proxyExceptions,
				browserProxyConfigURL: value.vpn ? '' : value.browserUrl,
				browserProxyExceptions: device.browserProxyExceptions,
				vpnOn: value.vpn,
				vpnProxy: value.vpn ? value.vpnProxy : 0,
				releaseChannel: device.releaseChannel,
				workOrder: device.workOrder,
			};

			this.deviceService
				.updateDevice(device.id, newData)
				.pipe(
					catchError(() => {
						throw new Error('Error updating internet settings');
					}),
				)
				.subscribe(() => {
					this.notificationService.open({
						variation: NotificationVariation.SUCCESS,
						message: `Internet settings for <strong>${device.name}</strong> were updated.`,
						duration: 5000,
					});
				});
		});
	}
	selectCompareValue(first: string | number, second: string | number) {
		return +first === +second;
	}

	updateForm() {
		const device = this.deviceService.device();

		if (!device) {
			return;
		}

		this.form.patchValue({
			vpn: device.vpnOn,
			browserUrl: device.browserProxyConfigURL,
			proxyPacUrl: device.proxyConfigURL,
			vpnProxy: device.vpnProxy,
		});
	}
}

import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
	selector: 'csd-app-app-checkbox',
	standalone: true,
	imports: [MatCheckboxModule, MatIconModule, MatTooltipModule],
	templateUrl: './app-item.component.html',
	styleUrl: './app-item.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCheckboxComponent {
	text = input.required<string>();
	checked = input<boolean>(false);
	description = input<string>('');

	change = output<boolean>();

	update(value: boolean) {
		this.change.emit(value);
	}
}

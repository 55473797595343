import { Component, input, output } from '@angular/core';
import { AuthService } from '@root/app/core/auth/auth.service';

import { ActionsComponent } from '../actions/actions.component';

@Component({
	selector: 'csd-app-guide',
	standalone: true,
	imports: [ActionsComponent],
	templateUrl: './guide.component.html',
	styleUrl: './guide.component.scss',
})
export class GuideComponent {
	email = input<string>('');

	continued = output<void>();

	constructor(private authService: AuthService) {}

	logout() {
		this.authService.logout().subscribe();
	}

	continue() {
		this.continued.emit();
	}
}

<div class="devices-comp">
	<csd-app-loader [isLoading]="isLoading()" class="space-y-5">
		@if (!groupSubscriptions().length) {
			<csd-app-empty-state icon="devices">
				<ng-content>This customer account currently has no devices linked.</ng-content>
				<ng-content empty-state-message>Add a device to get started.</ng-content>
				<ng-content empty-state-actions>
					<a routerLink="./add-device">
						<csd-app-button
							classList="w-full font-bold"
							size="small"
							type="tonal"
							variation="secondary"
						>
							Add device
						</csd-app-button>
					</a>
				</ng-content>
			</csd-app-empty-state>
		}

		@for (subscription of groupSubscriptions(); track subscription.subsriptionId) {
			<div class="devices-container rounded p-6 bg-layout-sky space-y-5">
				<div class="devices-container__header flex flex-col xs:flex-row items-center">
					<div class="grow flex flex-wrap gap-2">
						<csd-app-inline-message variation="success">
							Subscription #{{ subscription.subsriptionId }}
						</csd-app-inline-message>
						@if (subscription.note) {
							<csd-app-inline-message icon="chat">
								{{ subscription.note }}
							</csd-app-inline-message>
						}
					</div>
					<mat-divider class="separator"></mat-divider>
					<a routerLink="./add-device">
						<csd-app-button
							classList="w-full font-bold"
							iconClassList="font-bold"
							icon="add"
							size="small"
							type="tonal"
							variation="secondary"
						>
							Add Device
						</csd-app-button>
					</a>
				</div>

				@if (subscription.secureLockAndroid.length) {
					<div class="devices-list">
						<div class="devices-list__header">
							<img
								src="../../../assets/securelock-small-transparent-2048x322.png"
								alt="SecureLock Logo"
							/>
							<span>MDM</span>
						</div>
						<div class="w-full overflow-auto">
							<table mat-table [dataSource]="subscription.secureLockAndroid" class="devices-table">
								<ng-container matColumnDef="os">
									<th class="min-w-20 max-w-20 w-20 text-center" mat-header-cell *matHeaderCellDef>
										OS
									</th>
									<td class="text-center align-center" mat-cell *matCellDef="let element">
										<img
											src="../../../assets/android-48.png"
											class="max-w-7"
											alt="SecureLock Android"
										/>
									</td>
								</ng-container>
								<ng-container matColumnDef="deviceName">
									<th mat-header-cell *matHeaderCellDef>Device Name</th>
									<td mat-cell *matCellDef="let element">{{ element.name }}</td>
								</ng-container>
								<ng-container matColumnDef="licenseNumber">
									<th mat-header-cell *matHeaderCellDef>License Number</th>
									<td mat-cell *matCellDef="let element">{{ element.license }}</td>
								</ng-container>
								<ng-container matColumnDef="timer">
									<th mat-header-cell *matHeaderCellDef class="cell-text-center">Timer</th>
									<td mat-cell *matCellDef="let element" class="cell-text-center">
										<button
											class="rounded px-4 py-2"
											matRipple
											(click)="openTimer($event, element)"
											[class.text-layout-red]="element.overrideFinishedUtc"
										>
											<mat-icon inline class="material-symbols-filled">schedule</mat-icon>
										</button>
									</td>
								</ng-container>
								<tr mat-header-row *matHeaderRowDef="mdmColumns"></tr>
								<tr
									mat-row
									*matRowDef="let row; columns: mdmColumns"
									(click)="openDevice(row)"
								></tr>
							</table>
						</div>
					</div>
				}

				<!-- Routers Section -->
				@if (subscription.routers.length && false) {
					<div class="devices-list">
						<div class="devices-list__header">
							<img
								src="../../../assets/securelock-small-transparent-2048x322.png"
								alt="SecureLock Logo"
							/>
							<span>Connections</span>
						</div>
						<div class="w-full overflow-auto">
							<table mat-table [dataSource]="subscription.routers" class="devices-table">
								<ng-container matColumnDef="device">
									<th mat-header-cell *matHeaderCellDef class="min-w-20 max-w-20 w-20">Device</th>
									<td
										mat-cell
										*matCellDef="let element"
										class="cell-text-center align-center text-2xl leading-none"
									>
										<mat-icon inline class="material-symbols-filled text-amber-700"
											>router</mat-icon
										>
									</td>
								</ng-container>
								<ng-container matColumnDef="identifier">
									<th mat-header-cell *matHeaderCellDef>Identifier</th>
									<td mat-cell *matCellDef="let element">{{ element.identifier }}</td>
								</ng-container>
								<ng-container matColumnDef="timer">
									<th mat-header-cell *matHeaderCellDef class="cell-text-center">Timer</th>
									<td mat-cell *matCellDef="let element" class="cell-text-center">
										<button
											class="rounded px-4 py-2"
											matRipple
											(click)="openTimer($event, element)"
										>
											<mat-icon inline class="material-symbols-filled">schedule</mat-icon>
										</button>
									</td>
								</ng-container>
								<ng-container matColumnDef="status">
									<th mat-header-cell *matHeaderCellDef class="cell-text-center">Status</th>
									<td mat-cell *matCellDef="let element" class="cell-text-center">
										<mat-icon
											inline
											class="material-symbols-filled"
											[class.text-layout-red]="!element.isOnline"
											[class.text-layout-green]="element.isOnline"
											>circle</mat-icon
										>
									</td>
								</ng-container>
								<tr mat-header-row *matHeaderRowDef="connectionsColumns"></tr>
								<tr
									mat-row
									*matRowDef="let row; columns: connectionsColumns"
									(click)="openDevice(row)"
								></tr>
							</table>
						</div>
					</div>
				}
			</div>
		}
	</csd-app-loader>
</div>

import { Component, computed, inject, input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
	ActivatedRoute,
	Router,
	RouterLink,
	RouterLinkActive,
	RouterOutlet,
} from '@angular/router';
import { CustomersService } from '@root/app/feature/customers/customers.service';
import { SectionMenuComponent } from '@ui/section-menu/section-menu.component';

import { DEVICES_LINKS } from './device-layout.constants';

@Component({
	selector: 'csd-app-device-layout',
	standalone: true,
	imports: [MatIconModule, RouterOutlet, RouterLink, RouterLinkActive, SectionMenuComponent],
	templateUrl: './device-layout.component.html',
	styleUrl: './device-layout.component.scss',
})
export class DeviceLayoutComponent {
	router = inject(Router);
	route = inject(ActivatedRoute);
	customersService = inject(CustomersService);
	deviceName = input('');

	devicesLinks = computed(() =>
		DEVICES_LINKS.map((el) => ({
			...el,
			route: `/${this.customersService.customer()?.code}/devices/${this.deviceName()}/${el.route}`,
		})),
	);

	activePage = signal<string>('');
}

<div class="stats-page pb-5" [formGroup]="form">
	<h2 class="stats-page__device-name">
		@if (isEditingName()) {
			<input
				type="text"
				class="border rounded border-layout-lightgray text-base font-normal px-3 py-1"
				formControlName="name"
			/>
			<button class="action-button text-layout-green" matRipple>
				<mat-icon inline class="material-symbols-filled align-sub font-bold" (click)="saveName()"
					>check</mat-icon
				>
			</button>
			<button class="action-button" matRipple>
				<mat-icon
					inline
					class="material-symbols-filled align-sub font-bold"
					(click)="isEditingName.set(!isEditingName())"
					>close</mat-icon
				>
			</button>
		} @else {
			{{ device().name }}
			<button class="action-button" matRipple>
				<mat-icon inline class="material-symbols-filled align-sub" (click)="openEditName()"
					>edit</mat-icon
				>
			</button>
		}
	</h2>

	<div class="details-section">
		<div class="details-section__status">
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">campaign</mat-icon>
					Status
				</span>
				<span
					class="status-item__value status-button"
					[class.status-button--active]="device().active"
					>{{ device().active ? 'Active' : 'Disabled' }}</span
				>
			</p>
			<p class="status-item pb-5">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">schedule</mat-icon>
					Last Seen
				</span>
				<span class="status-item__value">{{ device().updated | date }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">id_card</mat-icon>
					License #
				</span>
				<span class="status-item__value">{{ device().license }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">verified</mat-icon>
					Version
				</span>
				<span class="status-item__value">{{
					device().appDisplayVersion || device().appVersion
				}}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">data_table</mat-icon>
					Updates Channel
				</span>
				<span class="status-item__value">
					<mat-select
						class="channel-selection"
						hideSingleSelectionIndicator
						formControlName="releaseChannel"
						[compareWith]="selectCompareValue"
						(selectionChange)="saveReleaseChange()"
					>
						@for (channel of updatesChannels; track channel.value) {
							<mat-option [value]="channel.value">{{ channel.label }}</mat-option>
						}
					</mat-select>
				</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled align-sub">lock</mat-icon>
					Unlock Code
				</span>
				<span class="status-item__value">
					@if (isPasswordVisible()) {
						{{ device().uninstallKey }}
					} @else {
						{{ '•••••••••••••••••' }}
					}
					<button class="text-layout-black px-1 align-sub" (click)="togglePassword()">
						<mat-icon inline class="material-symbols-filled">
							@if (isPasswordVisible()) {
								visibility_off
							} @else {
								visibility
							}
						</mat-icon>
					</button>
				</span>
			</p>
		</div>
		<div class="details-section__device-info">
			<p class="status-item">
				<span class="status-item__label">Manufacturer •</span>
				<span class="status-item__value">{{ device().manufacturer }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">Name •</span>
				<span class="status-item__value">{{ device().name }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">Model •</span>
				<span class="status-item__value">{{ device().model }}</span>
			</p>
			<p class="status-item pb-3">
				<span class="status-item__label">Serial Number •</span>
				<span class="status-item__value">{{ device().serialNumber }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">IMEI •</span>
				<span class="status-item__value">{{ device().imeI1 }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">Phone Number •</span>
				<span class="status-item__value">{{ device().imeI1Carrier }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">IMEI 2 •</span>
				<span class="status-item__value">{{ device().imeI2 }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">Phone Number 2 •</span>
				<span class="status-item__value">{{ device().imeI2Carrier }}</span>
			</p>
		</div>
	</div>
	<div class="device-commands">
		<csd-app-header-divider text="Device Commands" />
		<div class="device-commands__actions">
			@for (button of commandButtons; track button.command) {
				<csd-app-button
					classList="min-w-52"
					type="outlined"
					variation="primary"
					[icon]="button.icon"
					[disabled]="loadingCmdUpdates()"
					(click)="sendCommand(button.command)"
				>
					{{ button.label }}
				</csd-app-button>
			}
		</div>
	</div>
</div>

import { Injectable, signal } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

import { NotificationComponent } from './notification.component';

export enum NotificationAction {
	OPENED = 'opened',
	CLOSED = 'closed',
}

export enum NotificationVariation {
	ERROR = 'error',
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
}

export type NotificationDuration = 2000 | 5000 | 10000;

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	snackBarRef!: MatSnackBarRef<NotificationComponent>;
	subscription$: Subscription | null = null;

	action = signal<string>('');

	constructor(private snackBar: MatSnackBar) {}

	open(
		options: {
			variation?: NotificationVariation;
			panelClass?: string[];
			message?: string;
			duration?: NotificationDuration;
		} = {},
	) {
		if (this.snackBarRef) {
			this.close();
		}

		this.snackBarRef = this.snackBar.openFromComponent(NotificationComponent, {
			panelClass: [
				'notification-snackbar',
				`notification-${options.variation}`,
				...(options.panelClass || []),
			],
			...options,
			data: { message: options.message },
		});

		this.action.set(NotificationAction.OPENED);

		this.subscription$ = this.snackBarRef.onAction().subscribe(() => {
			this.action.set(NotificationAction.CLOSED);
		});
	}

	close() {
		this.snackBarRef?.dismissWithAction();
		this.subscription$?.unsubscribe();
	}
}

<div class="customers-comp">
	<csd-app-loader [isLoading]="isLoading()">
		<table mat-table [dataSource]="dataSource()">
			<!-- Code Column -->
			<ng-container matColumnDef="code">
				<th mat-header-cell *matHeaderCellDef width="20%">Code</th>
				<td mat-cell *matCellDef="let element" width="20%">{{ element.customerId }}</td>
			</ng-container>

			<!-- Name Column -->
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef width="30%">Name</th>
				<td mat-cell *matCellDef="let element" width="30%">{{ element.name }}</td>
			</ng-container>

			<!-- Weight Column -->
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef width="20%">Email</th>
				<td mat-cell *matCellDef="let element" width="20%">{{ element.email }}</td>
			</ng-container>

			<!-- Symbol Column -->
			<ng-container matColumnDef="phone">
				<th mat-header-cell *matHeaderCellDef width="20%">Phone</th>
				<td mat-cell *matCellDef="let element" width="20%">{{ element.phone }}</td>
			</ng-container>

			<!-- Symbol Column -->
			<ng-container matColumnDef="edit">
				<th mat-header-cell *matHeaderCellDef width="10%">Edit</th>
				<td
					mat-cell
					*matCellDef="let element"
					width="10%"
					class="text-layout-darkgray hover:text-layout-gray"
				>
					<a [routerLink]="[element.customerId, 'overview']" (click)="setCustomer(element)"
						><mat-icon>edit_note</mat-icon></a
					>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>

		@if (data()) {
			<mat-paginator
				[pageSizeOptions]="[10]"
				[showFirstLastButtons]="false"
				[pageSize]="10"
				hidePageSize
				aria-label="Select page of customers"
			>
			</mat-paginator>
		}
	</csd-app-loader>
</div>

import { Component, inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';

import { ActivityService } from './core/activity/activity.service';
import { AuthService } from './core/auth/auth.service';
import { CustomersService } from './feature/customers/customers.service';

@Component({
	selector: 'csd-app-root',
	standalone: true,
	imports: [RouterOutlet],
	template: `
		<router-outlet></router-outlet>
		<ng-template #view> Hola </ng-template>
	`,
})
export class AppComponent implements OnInit, OnDestroy {
	private activityService = inject(ActivityService);
	private authService = inject(AuthService);
	private customersService = inject(CustomersService);
	private matIconReg = inject(MatIconRegistry);
	private router = inject(Router);

	title = 'Control Stack Dashboard';
	@ViewChild('view', { static: true, read: ViewContainerRef })
	vcr!: ViewContainerRef;

	ngOnInit(): void {
		this.authService.checkAuth().subscribe();
		this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
		this.activityService.addListeners();

		this.router.events.subscribe((event) => {
			if (!(event instanceof ActivationStart)) {
				return;
			}

			const customerId = event.snapshot.params['customerId'];
			const oldCustomer = this.customersService.selectedCustomerId();
			const isSameCustomer = decodeURIComponent(customerId) && oldCustomer;

			if (customerId && !isSameCustomer) {
				this.customersService.setCustomerSelected(customerId);
			}
		});
	}

	ngOnDestroy(): void {
		this.activityService.removeListeners();
	}
}

import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { SectionMenuComponent } from '../../ui/section-menu/section-menu.component';
import { POLICIES_LINKS } from './policies.constants';

@Component({
	selector: 'csd-app-policies-layout',
	standalone: true,
	imports: [SectionMenuComponent, RouterOutlet],
	templateUrl: './policies-layout.component.html',
	styleUrl: './policies-layout.component.scss',
})
export class PoliciesLayoutComponent {
	policiesLinks = POLICIES_LINKS;
}

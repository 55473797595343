import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	effect,
	inject,
	signal,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ButtonComponent } from '@ui/button/button.component';
import { HeaderDividerComponent } from '@ui/header-divider/header-divider.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { NotificationService, NotificationVariation } from '@ui/notification/notification.service';
import { catchError, tap } from 'rxjs';

import { DeviceService } from '../../device/device.service';
import { DevicesService } from './../devices.service';
import { ANDROID_COMMANDS, COMMAND_BUTTONS, UPDATES_CHANNELS } from './stats.constants';

@Component({
	selector: 'csd-app-stats',
	standalone: true,
	imports: [
		ButtonComponent,
		DatePipe,
		HeaderDividerComponent,
		MatIconModule,
		MatOptionModule,
		MatProgressSpinnerModule,
		MatRippleModule,
		MatSelectModule,
		ReactiveFormsModule,
		LoaderComponent,
	],
	templateUrl: './stats.component.html',
	styleUrl: './stats.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsComponent {
	fb = inject(FormBuilder);
	changeDetectorRef = inject(ChangeDetectorRef);
	deviceService = inject(DeviceService);
	devicesService = inject(DevicesService);
	notificationService = inject(NotificationService);

	device = this.deviceService.device;
	selectedWorkOrder = this.devicesService.selectedWorkOrder;

	commandButtons = COMMAND_BUTTONS;
	updatesChannels = UPDATES_CHANNELS;
	form = this.fb.group({
		name: this.device().name,
		releaseChannel: this.device().releaseChannel,
	});

	isEditingName = signal(false);
	isPasswordVisible = signal(false);
	loadingCmdUpdates = signal(false);

	constructor() {
		effect(() => {
			if (this.device().id) {
				this.changeDetectorRef.detectChanges();
			}
		});
	}

	get baseDataToUpdate() {
		return {
			name: this.device().name,
			group: this.device().groupId || (this.device().group['id'] as string),
			uninstallKey: this.device().uninstallKey,
			releaseChannel: this.device().releaseChannel,
			workOrder: this.device().workOrder || this.selectedWorkOrder(),
		};
	}

	commandDevice(command: string) {
		const commandNumber = ANDROID_COMMANDS[command];
		const commandLabel = this.commandButtons.find((c) => c.command === command)?.label;
		const deviceId = this.device().id;

		this.loadingCmdUpdates.set(true);

		this.deviceService
			.sendCommand(deviceId, commandNumber)
			.pipe(
				tap(() => this.loadingCmdUpdates.set(false)),
				catchError(() => {
					this.loadingCmdUpdates.set(false);
					throw new Error(`Error updating device with command: ${commandLabel}`);
				}),
			)
			.subscribe(() => {
				this.openSuccessNotification(
					`Successfully applied command <strong>${commandLabel}</strong> to the device`,
				);
			});
	}

	openEditName() {
		this.isEditingName.set(true);
	}

	openSuccessNotification(message: string) {
		this.notificationService.open({
			variation: NotificationVariation.SUCCESS,
			message,
		});
	}

	sendCommand(command: string) {
		const deviceId = this.device().id;

		if (!deviceId) {
			throw new Error('No device id found');
		}

		this.commandDevice(command);
	}

	saveName() {
		const name = this.form.get('name')?.value;

		if (!name) {
			return;
		}

		const data = {
			...this.baseDataToUpdate,
			name: this.form.get('name')?.value as string,
		};

		this.deviceService.updateDevice(this.device().id, data).subscribe(() => {
			this.openSuccessNotification('Device name was updated.');
			this.isEditingName.set(false);

			this.deviceService.getDevice(this.device().id).subscribe();
		});
	}

	saveReleaseChange() {
		const releaseChannel = this.form.get('releaseChannel')?.value;

		const data = {
			...this.baseDataToUpdate,
			releaseChannel: parseInt(`${releaseChannel}` || '0'),
		};

		this.deviceService.updateDevice(this.device().id, data).subscribe(() => {
			this.openSuccessNotification('Release channel updated.');
		});
	}

	selectCompareValue(first: string | number, second: string | number) {
		return +first === +second;
	}

	togglePassword() {
		this.isPasswordVisible.set(!this.isPasswordVisible());
	}
}

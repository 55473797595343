import { Component, input } from '@angular/core';

@Component({
	selector: 'csd-app-header-divider',
	standalone: true,
	imports: [],
	templateUrl: './header-divider.component.html',
	styleUrl: './header-divider.component.scss',
})
export class HeaderDividerComponent {
	text = input.required();
}

export const VPN_FILTER_OPTIONS = [
	{
		label: 'SecureLock',
		value: 1,
	},
	{
		label: 'SafeSentry',
		value: 2,
	},
	{
		label: 'None = 100',
		value: 100,
	},
];
